import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import LogEntityChangeService from '../../services/system/LogEntityChangeService';
import iLogEntityChange, {
  LogEntityChangeTypes,
} from '../../types/system/iLogEntityChange';
import { iCellParams, iTableColumn } from '../../helpers/DynamicTableHelper';
import ModifyBy from '../common/ModifyBy';
import * as _ from 'lodash';
import Icons from '../frameWork/Icons';
import styled from 'styled-components';
import Lozenge from '../frameWork/Lozenge';
import EntityNames from '../../helpers/EntityNames';
import AddressHelper from '../../helpers/AddressHelper';
import UserHelper from '../../helpers/UserHelper';

type iLogEntityChangeList = iComponentWithPageHeader & {
  entityId: string;
  entityName: string;
};

const Wrapper = styled.div`
  .changes-table {
    tbody {
      border-bottom: 0px;
    }
  }
  .dynamic-tbl-cell-date {
    width: 180px;
  }
  .dynamic-tbl-cell-type {
    width: 80px;
  }
  .changes-table {
    .field-col {
      width: 10%;
    }
    .new-value-col,
    .old-value-col {
      width: 40%;
    }
  }
`;

const LogEntityChangeList = ({
  headerProps,
  entityId,
  entityName,
}: iLogEntityChangeList) => {
  const { renderDataTable } = useListCrudHook<iLogEntityChange>({
    sort: `createdAt:DESC`,
    getFn: (params) =>
      LogEntityChangeService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityId,
          entityName,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getObjectValue = (entityName: string, entity: any | null) => {
    if (!entity) {
      return null;
    }
    switch (entityName) {
      case `${EntityNames.Address}`: {
        return AddressHelper.getFullAddress(entity);
      }
      case `${EntityNames.User}`: {
        return UserHelper.getFullName(entity);
      }
      default: {
        return `${entity?.name || ''}`;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getChangedValue = (key: string, values: any | null) => {
    const data = values || {};
    if (!(key in data)) {
      return '';
    }
    const value = data[key];
    if (!('as' in value) || !value) {
      return value;
    }
    const { entity, entityName } = data[key];
    return getObjectValue(entityName, entity);
  };

  const getColumns = (): iTableColumn<iLogEntityChange>[] => [
    {
      key: 'date',
      header: 'Date',
      cell: ({ data }: iCellParams<iLogEntityChange>) => {
        return <ModifyBy at={data.createdAt} by={data.CreatedBy} />;
      },
    },
    {
      key: 'type',
      header: 'Type',
      cell: ({ data }: iCellParams<iLogEntityChange>) => {
        return (
          <Lozenge
            appearance={
              data.type === LogEntityChangeTypes.CREATE
                ? 'success'
                : data.type === LogEntityChangeTypes.DELETE
                  ? 'removed'
                  : 'default'
            }
          >
            {data.type}
          </Lozenge>
        );
      },
    },
    {
      key: 'changes',
      header: 'Changes',
      cell: ({ data }: iCellParams<iLogEntityChange>) => {
        const oldValues = data.oldValues || {};
        const newValues = data.newValues || {};
        const allKeys = _.uniq([
          ...Object.keys(oldValues),
          ...Object.keys(newValues),
        ]);
        return (
          <table className={'changes-table'}>
            <tbody>
              {allKeys.map((key) => {
                return (
                  <tr key={key}>
                    <td className={'field-col'}>
                      {_.capitalize(key.replace('Id', ''))}
                    </td>
                    <td className={'old-value-col'}>
                      {getChangedValue(key, oldValues)}
                    </td>
                    <td className={'trans-col'}>
                      <Icons.ArrowRightIcon label={''} />
                    </td>
                    <td className={'new-value-col'}>
                      {getChangedValue(key, newValues)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      },
    },
  ];

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <Wrapper>
        {renderDataTable({
          columns: getColumns(),
          tblProps: {
            testId: 'LogEntityChange-list',
          },
        })}
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default LogEntityChangeList;
